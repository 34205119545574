.container-list-business {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-items: center;
    width: 96%;
    border-radius: 12px;
    padding: 5px;
    gap: 1rem;
    box-shadow: 2px #7a7a7a;
}

.container-list-business:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.logo {
    width: 60px !important;
    height: 60px !important;
    background-color: #D9D9D9;
    border-radius: 50%;
    border: 1px solid #C3C3C3;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: end;
    align-items: flex-end;
  }

.logo-default {
    width: 70px !important;
    height: 70px !important;
    color: darkgray;
}