.glassmorphism {
  background: rgba(255, 255, 255, 0.12) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(4.5px) !important;
  -webkit-backdrop-filter: blur(4.5px) !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  padding: 0.8rem;
  width: 150px;
}

.buttonPlatform {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0.5rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(8.2px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
}

.buttonPlatform:hover {
  background: rgba(255, 255, 255, 0.83);
  transition: ease 0.3ms;
}
