.header-container {
    display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 2.5rem;
	height: 5.45rem;
}

.icon-notification {
    color: #787878;
    padding-right: 3rem;
}

.icon-menu-custom {
    color: #787878;
    background: none;
    border: none;
}