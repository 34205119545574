.header-content {
  display: flex;
  margin: 16px 8px 16px 8px;
  align-items: center;
}

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 95px);
  background-color: #f3f4f6;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
  }
}

.side-search {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 35%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  background-color: white;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
}

.hidden {
  display: none;
}

.list-clients {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
  gap: 6px;
}

.list-client {
  padding-left: 1rem;
  padding-right: 1rem;
}

.client-btn-details {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: start;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 12px;
}

.client-name {
  margin-left: 0px;
  color: #4E5461;
}

.client-info {
  margin-left: 8px;
  font-size: 14px;
  color: #8A8A8A;
  text-align: left;
}

.client-btn-details:hover {
  background-color: #F3F4F6;
  transition: all 0.3s ease;
}

.client-btn-details.actived {
  background-color: #3688EB;
  color: white;
  transition: all 0.3s ease;

  .client-name,
  .client-info {
    color: white;
  }
}

.client-btn-details.actived:hover {
  background-color: #3688EB;
}

.client-image {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #D9D9D9;
  margin-right: 24px;
}

.form {
  width: 100%;
  padding: auto;
}

.input-search-icon {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
}

.btn-search {
  border: none;
  background-color: transparent;
  position: absolute;
  padding-top: 4px;
  right: 18px;
}

.input-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
}

.form-search {
  position: relative;
  width: 100%;
  border: 0;
}

.add-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  background-color: #3688EB;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  @media (max-width: 768px) {
    position: fixed;
  }
}

.input-form-label {
  width: auto;
  margin: 10px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #686973 !important;
}

.input-form-text {
  width: 100%;
  margin: 10px;
  padding: 10px;
  color: #686973;
  border-radius: 12px;
  font-size: 14px;
}

.input-form-description {
  width: 100%;
  margin: 10px;
  height: 40px;
  padding: 10px;
  color: #686973;
  border: 1px solid #AAABB7;
  border-radius: 12px;
  font-size: 14px;
  resize: none;
}

.input-form-upload {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #AAABB7;
}

.button-upload {
  margin: 10px;
  padding: 10px 20px;
  background-color: #3688EB;
  color: white;
  cursor: pointer;
  border-radius: 12px;
  border: none;
}

.button-upload:hover {
  background-color: #227deb;
}

.form-content-address {
  display: flex;
  flex-wrap: wrap;
}

.input-form-address {
  width: 50%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.button-client-container {
  margin: 40px 0 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  background-color: #007bff;
  color: white;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: none;
}

.button-save {
  width: 100%;
  background-color: #3688EB;
  color: white;
  font-size: 20px;
  height: 60px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
}

.button-save:hover {
  background-color: #227deb;
}

.button-save:disabled {
  background-color: #AAACB2;
}

.button-delete-photo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.none-client {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  height: 100%;
  color: #686973;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  color: #007bff;
  padding-top: 2rem;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}