@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$PoppinsFont: "Poppins", serif;
$normalSizeFont: 14px;
$pfont: #575757;
$blue01: #3689ea;
$gray01: #595A5C;
$gray02: #585858;
$gray03: #424344;
$gray04: #c2c2c2;
$gray05: #f3f3f3;
$darkBlue: #393761;
$green01: #35a046;
$orange01: #ffa500;
$cardReservation01: #7377CB;
$cardReservation02: #39AF4D;
$cardReservation03: #B545E9;
$cancelButton: #AA1C1C;



.MuiOutlinedInput-input{
  cursor: pointer;
}

.legend {
  background-color: #3689ea;
}