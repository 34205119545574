@import '../global/variables/variables.scss';

.bold {
  font-weight: 600;
}

.border {
  padding: 0.5rem 0 1rem 0;
  border-bottom: 1px solid $gray04;
}

.containerModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  p {
    padding: 0;
    margin: 0;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 2rem;
  gap: 0.5rem;
  width: 38%;
  height: fit-content;
  border-radius: 20px;
  color: $pfont;
  overflow-y: auto;
  box-sizing: border-box;
}

.headerModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.headerTitle {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.editButton {
  font-size: 14px;
  color: $blue01;
  cursor: pointer;
}

.ownerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $gray04;
}

.ownerContainerInfo {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.ownerData {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  font-size: 14px;
}

.bookingDetails {
  margin: 1rem 0;
  display: flex;
  border: solid 1px $gray02;
  border-radius: 10px;
  gap: 0.4rem;
  padding: 0.4rem;
  flex-direction: column;
}

.alignData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelButton {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  border: none;
  background-color: $cancelButton;
  color: #fff;
}

.pendingStatus {
  background-color: rgba(252,152,15,1);
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}

.activeStatus {
  background-color: #074799;
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}

.cancelledStatus,
.inactiveStatus {
  background-color: rgba(178,14,14,1);
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}

.completedStatus,
.acceptStatus {
  background-color: rgba(61,131,97,1);
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}

.partialStatus,
.pendingAcceptStatus {
  background-color: rgba(31,38,130,1);
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}

.refuseStatus {
  background-color: rgba(178,14,14,1);
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}

.requestedStatus {
  background-color: rgba(252,152,15,1);
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}

.deleteButton {
  color: rgba(178,14,14,1);
}

.refundStatus {
  background-color: rgba(31,38,130,1);
  padding: 0.4rem !important;
  border-radius: 2rem;
  font-size: 13px;
  color: #fff;
}


//editModal
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editSaveButton{
  padding: 0.5rem 1rem !important;
}

// Responsividade para dispositivos móveis
@media (max-width: 768px) {
  .modal {
    width: 100%;
    height: 100%;
    padding: 1.2rem;
    border-radius: 0;
  }

  .headerTitle {
    gap: 0.5rem;
  }

  .ownerContainerInfo {
    padding: 0.6rem 0;
  }

  .bookingDetails {
    gap: 0.3rem;
    padding: 0.3rem;
  }

  .cancelButton {
    margin-top: 1.5rem;
    padding: 0.5rem 0.8rem;
  }

  .alignData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
}

@media (max-width: 480px) {
  .modal {
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 0;
  }

  .ownerContainerInfo {
    padding: 0.4rem 0;
  }

  .bookingDetails {
    margin: 0.4rem 0;
    padding: 0.3rem;
  }

  .cancelButton {
    padding: 0.4rem 0.6rem;
  }

  .alignData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.4rem;
  }
}
