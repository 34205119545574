@import '../../../../styles/global/variables/variables.scss';

.container-date-time {
    display: flex;
    gap: 1rem;
}

.input-date-time {
    width: 100%;
}

.container-add-other-service {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.7rem;
  height: 100%;
  p {
    font-weight: 400;
    color: $gray03;
    margin: 0;
    font-size: 16px;
  }
}

.input-size {
    height: 4rem !important;
}

.inputTimeContainer {
    display: flex;
    width: 100% !important;
    gap: 1rem;
    .inputTime {
      display: flex;
      width: 100% !important;
    }
  }

  .selectBlock {
    display: flex;
    width: 100%;
    padding: 0.8rem 0.8rem;
    justify-content: space-between;
    align-items: center;
    .MuiButton-label {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      color: #d3d3d3;
      text-transform: none;
    }
  }