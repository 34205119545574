.headerContainer {
  display: flex;
}

.inputTimeContainer{
  display: flex;
  justify-content: center;
}

.formContainer {
  display: flex;
  width: 100%;
}

.footerContainer {
  display: flex;
  align-items: end;
  width: 100%;
  height: 100%;
  Button {
    width: 100%;
  }
}

.btn-color-primary {
  background-color: #3688EB !important;
  color: #fff !important;
  border-color: #3688EB !important;
}