@import '../global/variables/variables.scss';

.date-header-name {
  display: flex;
  align-items: center;
  width: auto;
  margin: 9% 0 5% 5%;
  border: none;
  cursor: pointer;
}

.calendar {
  background-color: #eeeeee;
  width: 328px;
  position: sticky;
  top: 0;
  z-index: 10;
  overflow-y: auto;
  height: 100vh;
}

.saveButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-menu {
  display: none;
  padding-top: 0.8rem;
  margin-right: 5%;
  border: none;
}

.reservationCardBlock > div {
  position: absolute;
  box-sizing: border-box;
  padding: 10rem;
}

.ReservationCard {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  overflow: hidden;
}

.hourContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  height: 60px;
}

.hourContainer > div {
  position: absolute;
}

.date-header {
  display: flex;
  width: 95%;
  padding: 0 !important;
}

.floatingButton {
  display:none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  background-color: #007bff;
  color: white;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.newBooking {
  border-radius: 10px;
  background-color: #3689ea;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  transition: all ease 0.5s;
}

.newBooking:hover {
  background-color: #2e71be;
}

.show {
  display: flex !important;
}

.hiden {
  display: none !important;
}

@media (max-width: 820px) {
  .professionals-list {
    display: flex !important;
  }
  .sidebar {
    height: 100vh !important;
  }
  .calendar {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .floatingButton {
    display: flex;
    z-index: 3;
  }
  .date-header {
    display: none;
  }
  .date-header-name {
    display: none;
  }
  .newBooking{
    display: none;
  }
  .close-menu {
    display: flex;
    border: none;
  }
}
