@import '../../../styles/global/variables/variables.scss';

.newScheduleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.7rem;
  height: 100%;
  p {
    font-weight: 400;
    color: $gray03;
    margin: 0;
    font-size: 16px;
  }
}

.serviceText {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  p {
    font-size: 14px;
  }
}

.MuiOutlinedInput-input{
  padding: 1rem 0.5rem !important;
}

.scheduleOptionsButton {
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.scheduleOptionsButton div {
  display: flex;
  align-items: center;
  padding: 0rem;
  gap: 0.5rem;
}

.selectBlock {
  display: flex;
  width: 100%;
  padding: 1rem 0.5rem !important;
  justify-content: space-between;
  align-items: center;
  .MuiButton-label {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: $gray02;
    text-transform: none;
  }
}

.MuiInputBase-input {
  font-family: $PoppinsFont !important;
  font-size: 14px !important;
  color: $gray02 !important;
  width: 100%;
  padding: 1rem 0.8rem;
}

.MuiMenu-list {
  li {
    font-size: 16px;
    font-weight: 400;
    color: $gray02;
    font-family: $PoppinsFont;
  }
}

.inputTimeContainer {
  display: flex;
  width: 100%;
  gap: 1rem;
  .inputTime {
    display: flex;
    width: 100%;
  }
}

.inputDateHours {
  width: 100%;
}

.saveButton {
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: none;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  background-color: $blue01;
  p {
    color: #fff;
  }
}

.scheduleOptionsButton{
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  div{
    display: flex;
    align-items: center;
    padding: 0rem;
    gap: 0.5rem;
  }
}

.MuiButton-iconSizeMedium > *:first-child {
  font-size: 1rem !important;
}
.container-add-service {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  cursor: pointer;
}