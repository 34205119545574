.serviceBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.blockName{
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.2rem;
}

.blockData{
  display: flex;
  gap: 1.5rem;
}

.grayText{
  color: #9D9D9D !important;
}

.selectBlock {
  display: flex;
  width: 100%;
  padding: 0.8rem 0.8rem;
  justify-content: space-between;
  align-items: center;
}

