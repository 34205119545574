.toolBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigators {
  display: flex;
  gap: 1rem;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  button img {
    width: 20px;
    height: 20px;
  }
}

.dateCalendar {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active{
  background-color: #3689ea;
  color: #fff;
}

.active:hover{
  background-color:  #2e71be !important;
}

.viewButtons{
  display: flex;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
  button {
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    transition: all ease 0.5s;
  }
  button:hover{
    background-color: rgb(179, 179, 179);
  }
}

.rbc-agenda-event-cell{
  cursor: pointer;
  border-bottom: solid 1px;
}

.headerBreakModal{
  display: flex;
}

.title{
  margin-left: 1rem;
  p{
    font-weight: 500;
  }
}

.breakData{
  margin-top: 1rem;
  border: 1px solid;
  padding: 0.4rem;
  border-radius: 10px;
  div {
    margin-bottom: 0.5rem;
  }
}
