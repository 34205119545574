.btn-default {
  border-radius: 6px !important;
  height: 6vh !important;
}

.btn-size-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-size-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.btn-size-xl {
  padding: 0.75rem 1.5rem;
  font-size: 1.5rem;
}

.btn-w-80 {
  width: 80%;
}

.btn-color-primary {
  background-color: #3688EB !important;
  color: #fff !important;
  border-color: #3688EB !important;
}

.btn-color-primary-reverse {
  background-color: #fff !important;
  color: #3688EB !important;
  border-color: #3688EB !important;
}

.btn-color-white-blue {
  background-color: #3688EB !important;
  color: #ffffff !important;
  border: 1px solid #e4e4e4 !important;
}

.btn-color-blue-white {
  background-color: #ffffff !important;
  color: #3688EB !important;
  border: 1px solid #e4e4e4 !important;
}

.btn-color-secondary {
  background-color: #6c757d !important;
  color: #fff !important;
  border-color: #6c757d !important;
}

.btn-color-cancel {
  background-color: #AA1C1C !important;
  color: #fff !important;
  border-color: #AA1C1C !important;
}

.btn-outlined-secondary {
  background-color: transparent !important;
  border: 1px solid #6c757d !important;
  color: #6c757d !important;
}

.btn-outlined-secondary:hover {
  background-color: #6c757d !important;
  color: #fff !important;
}

.btn-outlined-primary {
  background-color: transparent !important;
  border: 1px solid #3688EB !important;
  color: #3688EB !important;
}

.btn-outlined-primary:hover {
  background-color: #3688EB !important;
  color: #fff !important;
}

.btn-receive-code-whatsapp {
  background-color: #fff !important;
  color: #3688EB !important;
  border-color: #3688EB !important;
  border-radius: 25px !important;
  border: 2px solid #3688EB !important;
}

.btn-circle {
  border-radius: 100%;
}

.btn-add-plus-color-bg-default {
  background-color: #3688EB !important;
}

.btn-add-plus-color-bg-default {
  color: #fff;
}

.disabled {
  background-color: #6c757d;
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-add-plus {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    position: fixed;
  }
}

.icon-add-plus-size {
  font-size: 32px !important;
}

.btn-shadow-none {
  box-shadow: none;
}

.btn-shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.box-shadow-lg {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.box-shadow-xl {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.pix-copy {
  background-color: #4CAF50 !important;
}