@import "../../../../styles/global/variables/variables.scss";

.containerList {
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 30%;
	padding-bottom: 1rem;
}
@media (max-width: 768px) {
    .containerList {
      width: 95%;
    }
}

.blockCustom {
	display: flex;
	width: 100%;
	height: fit-content;
	color: black;
	border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
}

.containerData {
	padding: 0.5rem;
	width: 30%;
	display: flex;
	flex-direction: column;
    justify-content: center;
	align-items: center;
	gap: 0.1rem;
}

.containerInfo {
	padding: 0.5rem;
	width: 70%;
	display: flex;
	align-items: center;
    background-color: $gray05;
    justify-content: space-between;
}

.infoClient {
    width: 60%;
}

.info {
	display: flex;
	flex-direction: column;
    justify-content: center;
	align-items: center;
	gap: 0.2rem;
    height: fit-content;
}

.colorTypePayment {
	color: $blue01;
}

.font {
	font-weight: 700 !important;
}

.size {
	font-size: 20px !important;
}

.sizeSmall {
	font-size: 14px !important;
}

.cursor {
	cursor: pointer;
}