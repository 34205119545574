.dateHeader {
  display: flex;
  padding: 1rem;
  align-items: center;
}

.dateHeaderTitle {
  color: #1e2125 !important;
  font-size: 16px !important;
  font-family: "Poppins", serif !important;
}

.opening-hours {
  display: flex;
}

.dynamic-hours {
  width: 55%;
  height: auto;
  border-right: 1px solid #AAABB7;
  padding-bottom: 2rem;
}

.dynamic-hours-content {
  border-bottom: 1px solid #AAABB7;
}

.configuration-hours {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  gap: 16px;
  width: 40%;
  padding-top: 20px;
  padding-left: 20px;
}

.form-content-opening-hours {
  width: 100%;
}

.input-form-opening-hours {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  p{
    margin-left: 0.7rem;
  }
}

.input-form-label-opening-hours {
  width: auto;
  font-size: 14px !important;
  color: #28292e !important;
  font-family: "Poppins", serif !important;
  font-weight: 500 !important;
}

.input-form-text-interval {
  width: 150px;
  padding: 10px;
  color: #686973;
  border: 1px solid #AAABB7;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.input-form-checkbox-opening-hours {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.input-form-dynamic {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 10px;
}

.input-form-dynamic-secondary {
  display: flex;
  padding: 0 20px 20px 20px;
  align-items: center;
  gap: 10px;
}

.input-form-dynamic-control {
  display: flex;
  align-items: center;
  width: 260px;
  gap: 20px;
}

.input-form-dynamic-fields {
  display: flex;
  align-items: center;
  gap: 20px;
}

.input-form-dynamic-fields-secondary {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.input-form-dynamic-action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-form-dynamic-button {
  background-color: white !important;
  border: none !important;
  color: #3688EB;
  font-size: 24px;
  min-width: 28px;
}

.input-form-dynamic-button:hover {
  color: #227deb;
  cursor: pointer;
}

.input-form-dynamic-remove-button {
  background-color: white !important;
  border: none !important;
  color: #eb3636;
  font-size: 24px;
  min-width: 28px;
}

.input-form-dynamic-remove-button:hover {
  color: #ce2929;
  cursor: pointer;
}

.button-container-opening-hours {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container-opening-hours-mobile{
  display: none; 
}

.buttonSave{
  margin: 1rem 0;
  padding: 1rem 8rem;  
  background-color: #3f89e4;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.button-save:hover {
  background-color: #155099;
}

.buttonSave-disabled {
  background-color: #AAACB2;
}

.dynamic-hours-content-placeholder {
  display: flex;
  margin: 50px;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1025px) {
  .opening-hours {
    flex-wrap: wrap;
    margin: 0 !important;
    flex-direction: column;
  }
  
  .dynamic-hours {
    width: 100%;
  }

  .button-container-opening-hours{
    display: none;
  }

  .configuration-hours {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    gap: 16px;
  }

  .input-form-text-interval {
    width: 100% !important;
  }
  .button-container-opening-hours-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
}

@media (max-width: 426px) {
  .input-form-dynamic-control {
    width: 120px;
    max-width: 120px;
    min-width: 120px;
  }
  .buttonSave{
    width: 90%;
    padding: 0.8rem;
    font-size: 14px;
    margin-top: 2rem;
  }
  .date-header{
    padding: 0 !important;
  }
}

@media (max-width: 376px) {
  .input-form-dynamic {
    padding: 10px;
    gap: 2px;
  }
}
