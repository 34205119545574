.pendingStatus {
	background-color: rgba(54, 136, 235) !important;
	padding: 0.4rem !important;
	border-radius: 2rem;
	font-size: 13px;
	color: #fff !important;
}

.processingStatus {
	background-color: #ec6f25 !important;
	padding: 0.4rem !important;
	border-radius: 2rem;
	font-size: 13px;
	color: #fff !important;
}

.availableStatus {
	background-color: #4caf50 !important;
	padding: 0.4rem !important;
	border-radius: 2rem;
	font-size: 13px;
	color: #fff !important;
}

.indefiniteStatus {
  background-color: #C7C7C7 !important;
  padding: 0.4rem !important;
	border-radius: 2rem;
	font-size: 13px;
	color: #fff !important;
}
