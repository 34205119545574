.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.serviceContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0 1rem 0;
}

.frequencySelect{
  display: flex;
  width: 100%;
}

.textDivider {
  margin: 1rem 0 1rem 0;
}

.frequencyForm {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.repetitionBlock{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }
}

.buttonContainer{
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-top: 0 !important;
}

.buttonCalc{
  background-color: #3688EB !important;
  border: none !important;
  color: #fff !important;
  letter-spacing: 0.15rem !important;
  position: fixed;
  padding: 0.6rem 1rem !important;
}

.calculatedList {
  display: flex;
  margin-top: 0.8rem;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: 2px;
  flex-direction: column;
  height: 100%;
}

.listItem{
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  border-bottom: solid 1px #c4c0c0;
}
