.form-service {
    padding: 8px 24px 8px 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
  }
  
  .input-form-service {
    width: 100%;
  }
  
  .input-form {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .input-form-input{
    width: 90%;
  }
  
  .input-form-label {
    padding-left: 6px;
  }
  
  @media (max-width: 768px) {
    .form-service {
      display: grid;
      grid-template-columns: 100%;
      padding: 2px;
    }
  }