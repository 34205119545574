@import '../global/variables/variables.scss';

.calendar-container {
  flex-direction: column;
  align-items: center;
  p{
    font-family: $PoppinsFont;
  }
}

.containerMonthNav{
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  cursor: pointer;
}

.calendarHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    color: #686161;
    width: 160px;
    font-weight: 500;
  }
}

.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin-left: 2px;
}

.calendar-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  p{
    padding: 0.43rem;
    cursor: pointer;
    font-family: $PoppinsFont;
    font-size: 16px;
    &:hover{
      color: #686161;
    }
  }
}

.calendar-week {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.calendar-day {
  width: 30px;
  height: 30px;
  color: darkgray;
  font-size: 12px;
  padding: 2px;
  text-align: center;
  border-radius: 15px;
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-day {
  color: #3689ea;
  background-color: #e0f7fa;
  width: 30px;  
  height: 30px;  
  border-radius: 50%;  
  display: flex;  
  align-items: center;  
  justify-content: center;  
  margin-top: 0;
}

.notification-dot {
  background-color: #3689ea;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}

.scheduleBrake-dot {
  background-color: #B8001F;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 2px;
}

.container-calendar-weekday {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-weekday {
  color: #ada1a1;
  text-align: center;
}
.previous-month {
  color: lightgray;
  font-style: italic; 
}

.calendar-date {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 0.5rem;
  p{
    margin: 0.5rem 0;
  }
}

.menu-mobile {
  display: none;
}

.menu-business-name {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;    
  }
}

@media (max-width: 820px) {
  .calendar-grid {
    transition: all 0.7s ease-in-out;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
    text-align: center;
    p {
      padding: 0.3rem;
    }
  
    &.collapsed {
        max-height: 50px;
    }
  
    &.expanded {
        max-height: 500px;
        opacity: 1;
    }
  }

  .containerCalendarGrid{
    font-size: 12px;
  }

  .calendar-week {
    display: contents;
  }

  .container-calendar-weekday {
    gap: 0;
  }

  .calendar-day {
    width: 40px;
    height: 40px;
    font-size: 12px;
    margin-right: 0;
    border-radius: 20px;
  }

  .selected-day {
    color: #3689ea;
    background-color: #e0f7fa;
    font-size: 12px;
    margin-top: 0;
  }

  .calendar-container {
    padding: 10px;
  }

  .notification-dot {
    width: 8px;
    height: 8px;
    top: 4px;
    right: 4px;
  }

  .calendar-weekday {
    font-size: 14px;
    padding: 5px;
    min-width: 40px;
    text-align: center;
  }

  .container2 {
    margin-left: 0;
  }
  .calendarHeader {
    padding: 0;
    justify-content: space-between;
  }

  .menu-mobile {
    display: block;
    background-color: transparent;
    margin-right: 1rem;
    border: none;
  }
}
