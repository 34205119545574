.buttonContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.circleBox {
  position: relative;
}

.imgContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.contentContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

@media screen and (max-width: 768px) {
  .circleBgForMobile{
    width: 180vw;
    left: -45%;
    bottom: -16%;
    height: 150%;
    background-color: #f1f1f1;
    border-radius: 50%;
    position: absolute;
  }

  .buttonContainer{
    margin-top: 3rem;
  }

  .imgContainer,
  .contentContainer{
    position: relative;
    z-index: 2;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .imgContainer,
  .contentContainer {
    width: 100vw;
  }
  .imgContainer{
    margin-top: 30%;
  }
  .buttonContainer{
    margin-top: 20%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
  .imgContainer,
  .contentContainer {
    width: 100%;
  }

  .imgContainer{
    margin-top: 0%;
  }

  .buttonContainer {
    margin-top: 5%;
  }
}
