@import '../global/variables/variables.scss';

*{
  font-family: $PoppinsFont;
}

$button-radius: 10px;
$button-padding: 0.5rem 2rem;
$button-width: 50%;
$transition: all 0.3s ease;

.MuiContainer-root {
  padding: 1rem !important;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
}
.MuiAccordion-root{
  background-color: none !important;
}

.TitlePlans{
  font-weight: 600;
  color: $darkBlue;
  width: 60%;
  margin: 0;
}
.planContainer{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 16px;
}
.planBox {
  position: relative;
	padding: 0.8rem;
  margin-right: 0 !important;
	border-radius: 8px;
	background-color: white;
	z-index: 1;
  max-height: auto;
  border: solid 1px #E5E6E6;
}

.planButton{
  font-family: $PoppinsFont !important;
  font-weight: 500 !important;
}

.borderAdvanced {
  padding: 2px;
  background-image: linear-gradient(45deg, #3DAAC4, #B443CB);
  border-radius: 8px;
}

.privacyPolicy{
  font-size: 14px;
  font-family: $PoppinsFont;
  font-weight: 500;
  color: #b1b1b1;
  margin: 8px 0 8px 0;
}

.planTitle {
  color: #424344;
  font-weight: 500;
  margin: 0;
}

.planSubs {
  color: $gray01;
  margin-top: 6px;
  margin-bottom: 8px;
}

.advancedPlanBox {
  position: absolute;
  right: -37px;
  top: -22px;
  width: 120px;
  height: 40px;
}

.periodBox {
  display: flex;
  width: 75%;
  margin: 0 auto;
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 0.2rem;
}

@mixin buttonBase {
  font-size: $normalSizeFont;
  font-weight: 500;
  border-radius: 10px;
  padding: 0.5rem 2rem;
  width: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.buttonActivated {
  @include buttonBase;
  background-color: #ffffff;
  color: #3688eb;
  border: 1px solid #b1b1b1;

  &:hover {
    background-color: #e6e6e6;
  }
}

.buttonDefault {
  @include buttonBase;
  color: #b4b4b4;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: #f0f0f0;
  }
}

.MuiPaper-root.MuiAccordion-root {
  background-color: transparent !important;
  box-shadow: none !important;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  display: none !important;
}

.planPrice,.planDetails {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.planDetails{
  margin-right: 0.1rem;
  color: #3689EA;
}

.planPrice{
  color: $gray03;
  span{
    font-size: 12px;
    color: $gray02;
  }
}

.planDescription {
  font-family: $PoppinsFont;
  font-size: 14px;
  line-height: 2 ;
  margin: 0;
}

.accordionContainer{
  display: flex;
  font-family: $PoppinsFont;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;
}

.detailsContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: fit-content;
  margin: 0 !important; 
  gap: 11rem;
  border-bottom: solid 1px #E5E6E6;
}

@media (max-width: 768px) {
  .detailsContainer {
    gap: 5.5rem;
  }
}