@import'../global/variables/variables.scss';


.captionContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  p {
    font-family: $PoppinsFont;
    color: $gray03;
    font-size: 14px;
    text-align: center;
  }
}

.categoriesSlider {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  gap: 8px;
  scroll-behavior: smooth;
}

.categoriesTitle {
  display: flex;
  p {
    font-size: 14px;
    color: $darkBlue;
    font-weight: 600;
    margin: 0;
  }
}

.servicesTitle {
  display: flex;
  p {
    font-size: 14px;
    color: $darkBlue;
    font-weight: 600;
    margin: 0;
  }
}

.categoriesSlider::-webkit-scrollbar {
  height: 6px;
}

.categoriesSlider::-webkit-scrollbar-thumb {
  background-color: #3688EB;
  border-radius: 10px;
}

.servicesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  
  p {
    font-family: $PoppinsFont;
    color: $gray03;
    font-size: 14px;
    margin: 0;
  }
}

.servicesContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  padding: 0.5rem;
  justify-items: center;
  align-items: center;
  width: 95%;
  border-top: 1px solid $gray04;
  cursor: pointer;
  border-radius: 0 0 0.5rem 0.5rem ;
  p {
    font-size: 14px;
    font-family: $PoppinsFont;
    color: $gray03;
    margin: 0;
  }
}

.servicesContainer:hover{
  background-color: #f3f3f3;
  transition: 0.7s ease;
}

.servicesDadContainer{
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}