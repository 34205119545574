.pendingStatus {
    background-color: rgba(252,152,15,1) !important;
    padding: 0.4rem !important;
    border-radius: 2rem;
    font-size: 13px;
    color: #fff !important;
  }
  
  .activeStatus {
    background-color: #074799 !important;
    padding: 0.4rem !important;
    border-radius: 2rem;
    font-size: 13px;
    color: #fff !important;
  }
  
  .cancelledStatus,
  .inactiveStatus {
    background-color: rgba(178,14,14,1) !important;
    padding: 0.4rem !important;
    border-radius: 2rem;
    font-size: 13px;
    color: #fff !important;
  }
  
  .completedStatus,
  .acceptStatus {
    background-color: rgba(61,131,97,1) !important;
    padding: 0.4rem !important;
    border-radius: 2rem;
    font-size: 13px;
    color: #fff !important;
  }
  
  .partialStatus,
  .pendingAcceptStatus {
    background-color: rgba(31,38,130,1) !important;
    padding: 0.4rem !important;
    border-radius: 2rem;
    font-size: 13px;
    color: #fff !important;
  }
  
  .refuseStatus {
    background-color: rgba(178,14,14,1) !important;
    padding: 0.4rem !important;
    border-radius: 2rem;
    font-size: 13px;
    color: #fff !important;
  }
  
  .requestedStatus {
    background-color: rgba(252,152,15,1) !important;
    padding: 0.4rem !important;
    border-radius: 2rem;
    font-size: 13px;
    color: #fff !important;
  }
  