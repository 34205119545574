.professionalContent{
  display: flex;
  gap: 2rem;
  border: solid 1px #A3A3A3;;
  border-radius: 1rem;
  align-items: center;
  width: 95%;
  padding: 0.5rem;
}

.professionalContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.listServices{
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 100%;
  height: 300vh;
  overflow-y: auto;
}

.buttonContainer {
  display: flex;
  width: 100%;
}

.btn-color-primary {
  background-color: #3688EB !important;
  color: #fff !important;
  border-color: #3688EB !important;
  padding: 0.5rem 1rem !important;
}
