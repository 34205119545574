:root {
  --gradient-shadow: linear-gradient(45deg,
      #943baa,
      #5d3a9b,
      #2b8da3,
      #2a5795,
      #943baa,
      #5d3a9b,
      #2b8da3,
      #2a5795,
      #943baa);
}

.shadow {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
}

.shadow:before,
.shadow:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background: var(--gradient-shadow);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: animate 20s linear infinite;
  border-radius: 12px;
}

.shadow:after {
  filter: blur(15px);
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 300% 0;
  }

  100% {
    background-position: 0 0;
  }
}