.App {
	height: 100vh;
	color: #fff;
}

.calendar {
	overflow-x: hidden;
}

@media (min-width: 1024px) {
	::-webkit-scrollbar {
		width: 0.4rem;
		height: 0.4rem;
	}

	.scroll-menu ::-webkit-scrollbar {
		width: 0.4rem;
		height: 0.4rem;
		display: none;
		transition: all 1s ease;
	}

	.scroll-menu:hover ::-webkit-scrollbar {
		display: block;
		transition: all 1s ease;
	}

	::-webkit-scrollbar-corner {
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		border: 6px solid transparent;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}

	:hover::-webkit-scrollbar-thumb {
		border: 6px solid transparent;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		border: 6px solid transparent;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}
}