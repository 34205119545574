@import '../../styles/global/variables/variables.scss';

.container-values {
    display: flex;
    gap: 2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    width: 10%;
    padding: 1.5rem;
    border: 1px solid darkgray;
    border-radius: 12px;
    margin-top: 1rem;
}
@media (max-width: 768px) {
    .container {
      width: 25%;
    }
}

.btn-withdraw {
    width: 30%

}
@media (max-width: 768px) {
    .btn-withdraw {
      width: 90%;
    }
}

.balance-total {
    font-size: 20px;
    font-weight: 700 !important;
}

.balance-pending {
    font-size: 20px;
    font-weight: 700 !important;
    color: $blue01 !important;
}

.balance-available {
    font-size: 20px;
    font-weight: 700 !important;
    color: $green01 !important;
}

.balance-processing {
    font-size: 20px;
    font-weight: 700 !important;
    color: $orange01 !important;
}

.container-main {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

.dialogBtnClose {
    height: fit-content; 
    width: 100%; 
    display: flex; 
    justify-content: end;
}

.dialogBalanceAvailable {
    border: none; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.dialogContainerText {
    border: none;
}

.dialogContainerBtn {
    display: flex;
    align-items: center; 
    margin-top: 1rem; 
    width: 100%; 
    gap: 1rem;
}

.dialogSecondTitle {
    display: flex; 
    align-items: center; 
    gap: 1rem;
}

.dialogSecondText {
    color: red;
}

.dialogSecondTitle {
    color: $blue01;
}