.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem 2rem;
  color: #fff;
  margin: auto;
}

.loading-content span {
  margin-top: 1.5rem;
}