.text {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #3C3D37;

  &--title {
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    line-height: 1.4 !important;
    margin-bottom: 0.3rem !important;
  }

  &--mid {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    line-height: 1.3 !important;
  }

  &--body {
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: 1.2 !important;
  }

  &--body1 {
    font-size: 12px !important;
    font-weight: normal !important;
    line-height: normal !important;
  }

  &--sideBar {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
  }

  &--align-left {
    text-align: left !important;
  }

  &--align-center {
    text-align: center !important;
  }

  &--align-right {
    text-align: right !important;
  }

  &--align-justify {
    text-align: justify !important;
  }
}
