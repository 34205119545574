.clientContainer {
  width: 100%;
  overflow-y: auto;
}

.clientItem{
  cursor: pointer;
  width: 100%;
	display: flex;
	align-items : center;
	margin: 10px 0;
  border-radius: 6px;
  border-bottom: 1px solid;
}

.clientDataContainer {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  gap: 0.3rem;
}

.clientDataPhone {
  font-size: 14px;
}

.clientItem:hover {
  background-color: #f1f1f1;
}