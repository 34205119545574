.componentContainer{
  display: flex;
  width: 100%;
  border-radius: 1rem;
  height: fit-content;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #FBFBFB;
}

.serviceData{
  background-color:#3688EB;
  display: flex;
  flex-direction: column;
  width: 20%;
  border-radius: 1rem 0rem 0rem 1rem ;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
}

.serviceInfo{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  div{
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.serviceDetails{
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  margin-right: 1rem;
}

.actionContainer{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-right: 1rem;
}