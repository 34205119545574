.side-client {
  display: flex;
  width: 65%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.client-btn-details {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: start;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 16px;
}

.client-name {
  color: #4E5461;
  font-weight: 400;
  margin-left: 10px;
}

.client-info {
  color: #8A8A8A;
}

.box-client {
  width: 100%;
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  cursor: default;
  align-items: self-start;
  justify-content: start;
  background-color: transparent;
  border: none;

  @media (max-width: 768px) {
    padding: 24px;
  }
}

.box-client .client-image {
  width: 64px;
  height: 64px;
  position: relative;
}

.client-image-upload {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background-color: #3688EB;
  color: white;
  cursor: pointer;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 0;
  right: -8px;
}

.client-image-upload svg {
  width: 16px;
  height: 16px;
}

.client-picture {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-end;
}

.client-btns {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  background-color: #FFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  color: #1E1E1EB2;
  cursor: pointer;
}

.client-btns svg {
  width: 24px;
  height: 24px;
}

.btn-close {
  display: none;
}

@media (max-width: 768px) {
  .btn-close {
    display: block;
    position: absolute;
    background-color: transparent;
    box-shadow: none;
    top: 1rem;
    right: 1rem;
    color: #1E1E1EB2;
  }
}

.client-schedules {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 48px;
}

.schedules {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.schedules-number {
  min-width: 16px;
  font-size: 16px;
  font-weight: bold;
  background-color: #3688EB;
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
  text-align: center;
}

.schedules-number.cancel {
  background-color: #B43535;
}

.client-details h4 {
  font-weight: 500;
  color: #686973;
}

.client-btn-details:hover {
  background-color: #F3F4F6;
  transition: all 0.3s ease;
}

.client-btn-details.actived {
  background-color: #3688EB;
  color: white;
  transition: all 0.3s ease;

  .client-name,
  .client-info {
    color: white;
  }
}

.client-btn-details.actived:hover {
  background-color: #3688EB;
}

.client-image {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #D9D9D9;
  margin-right: 24px;
}

/* Box Schedule */
.list-schedules {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 18px;
  margin-top: 32px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.box-schedule {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: start;
  align-items: stretch;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: none;
}

.schedule-date {
  width: 25%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  color: #4E5461;
  border-right: 1px solid #848484;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.schedule-date-info {
  font-weight: 700;
}

.schedule-infos {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  color: #4E5461;
  font-size: 12px;
  padding: 12px 12px;
  gap: 10px;
}

.schedule-price {
  width: 25%;
  font-weight: 500;
  color: #3688EB;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.schedule-service {
  font-weight: 500;
  font-size: 14px;
}

.schedule-professional {
  font-weight: 400;
  font-size: 12px;
}

.schedule-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  color: #4E5461;
  align-items: self-start;
}

.schedule-badge {
  margin-top: 8px;
  padding: 2px 10px 3px;
  color: white;
  border-radius: 12px;
}

.schedule-badge.cancel {
  background-color: #B43535;
}

.schedule-badge.active {
  background-color: #3688EB;
}

.none-schedule {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #686973;
  height: 100%;
  font-style: italic;
}

.input-client-text {
  width: 95%;
  color: #686973;
  border: 1px solid #AAABB7;
  padding: 10px;
  border-radius: 12px;
  font-size: 14px;
}

.input-client-text input {
  color: #686973;
  border: 1px solid #AAABB7;
  border-radius: 12px;
  background-color: white;
}

.input-client-text .MuiOutlinedInput-root {
  border: none !important;
  border-radius: 12px;
}

.button-container {
  margin: 2rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.button-cancel {
  width: 100%;
  background-color: transparent;
  color: #686973;
  font-size: 20px;
  height: 60px;
  border: 1px solid #686973;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 1rem;
}

.btn-delete {
  background-color: #B43535;
  color: white;
}