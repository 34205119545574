.date-header {
  display: flex;
  margin: 16px 8px 16px 8px;

  align-items: center;
}

.business-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 40px;

  @media (min-width: 1024px) {
    width: 70% !important;
  }

  @media (min-width: 1280px) {
    width: 80% !important;
  }

  @media (min-width: 1920px) {
    width: 1280px !important;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 10px 1rem 10px 1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: 90% !important;
  }
}

.form-cover {
  border: 1px solid #AAABB7;
  border-radius: 12px;
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: end;
}

.form-cover-button-upload {
  margin: 10px;
  border-radius: 12px;
  background-color: rgb(34, 125, 235);
  border: none;
  color: white;
  padding: 10px 7px 5px 10px;
  cursor: pointer;
}

.form-cover-button-upload:hover {
  background-color: #227deb;
}

.form-cover-button-uploaded {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: #AAACB2 !important;

  &:hover {
    background-color: #AAACB2 !important;
  }
}

.form-logo {
  margin-top: -100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.profile-logo {
  width: 200px;
  height: 200px;
  background-color: #D9D9D9;
  border-radius: 50%;
  border: 1px solid #C3C3C3;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.form-logo-button-upload {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #3688EB;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #AAABB7;
  padding: 1rem;
}

.input-form {
  width: auto !important;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: flex-start !important;
  border: none !important;
  border-bottom: 1px solid #AAABB7 !important;
}

.input-form-label {
  margin: 10px !important;
  color: #686973 !important;
}

.input-form-text {
  width: 100%;
  margin: 10px;
  padding: 10px;
  color: #686973;
  border: 1px solid #AAABB7;
  border-radius: 12px;
  font-size: 14px;
}

.input-form-description {
  width: 100%;
  margin: 10px;
  height: 40px;
  padding: 10px;
  color: #686973;
  border: 1px solid #AAABB7;
  border-radius: 12px;
  font-size: 14px;
  resize: none;
}

.input-form-upload {
  display: flex;
  justify-content: start !important;
  flex-wrap: wrap;
  align-items: flex-start !important;
  border-bottom: 1px solid #AAABB7;
}

.button-upload {
  margin: 10px;
  padding: 10px 20px;
  background-color: #3688EB;
  color: white;
  cursor: pointer;
  border-radius: 12px;
  border: none;
}

.button-upload:hover {
  background-color: #227deb;
}

.form-content-address {
  display: grid;
  flex-wrap: wrap;
  grid: 1fr 1fr;
}

.input-form-address {
  width: 50%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.input-form-cep {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
}

.input-form-text-address {
  width: 100%;
  margin: 10px;
  padding: 10px;
  color: #686973;
  border: 1px solid #AAABB7;
  border-radius: 12px;
  font-size: 14px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-content: center;

  @media (min-width: 1024px) {
    width: 70% !important;
  }

  @media (min-width: 1280px) {
    width: 80% !important;
    margin-left: 40px !important;
    margin-bottom: 40px !important;
  }

  @media (min-width: 1920px) {
    width: 1280px !important;
  }

  @media (max-width: 768px) {
    margin: 10px 1rem 10px 1rem !important;
  }

  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
    width: 92% !important;
    margin-top: 1rem !important;
  }
}

.photos-profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.photos-input-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.list-photos {
  padding: 0.6rem;
  color: #686973;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.list-photos div {
  position: relative;
  width: 152px;
  height: 100px;
  border-radius: 12px;
  margin: 0.6rem;
  border: 1px solid #AAABB7;
}

.list-photos div button {
  position: absolute;
  background-color: #ff3b30;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: -0.6rem;
  top: -0.6rem;
}

.button-save {
  width: 100%;
  background-color: #3688EB;
  color: white;
  font-size: 20px;
  height: 60px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
}

.button-save:hover {
  background-color: #227deb;
}

.button-save:disabled {
  background-color: #AAACB2;
}

.button-delete-photo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {

  /* Tablets */
  .form-cover {
    height: 180px;
    justify-content: center;
    /* Ajustar alinhamento para telas menores */
  }
}

@media (max-width: 768px) {

  /* Mobile */
  .form-cover {
    height: 220px;
  }
}

@media (max-width: 480px) {
  .form-business-profile {}

  /* Small Mobile */
  .form-cover {
    height: 220px;
    width: 80%;
  }
}