.payment-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 1024px) {
    width: 70% !important;
  }

  @media (min-width: 1280px) {
    width: 80% !important;
  }

  @media (min-width: 1920px) {
    width: 1280px !important;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 10px 1rem 10px 1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: 90% !important;
  }
}

.payment-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 2rem;
  box-sizing: border-box;

  &.mobile {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.payment-box-inside {
  border-radius: 8px;
  padding: 1rem;
  margin-top: 2rem;
  width: 80%;

  &.bg-gray {
    background-color: #f9f9f9;
  }

  &.mobile {
    width: 100%;
  }
}

.btn-back {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}